import React from 'react';
import dynamic from 'next/dynamic';

import MobileContext from '@web/components/MobileContext';

const Desktop = dynamic(
	() => import('@web/components/app-layout/Menu/Desktop'),
);
const Mobile = dynamic(() => import('@web/components/app-layout/Menu/Mobile'));

const Menu: React.FC<any> = props => {
	const isMobile = React.useContext(MobileContext);
	return isMobile ? <Mobile {...props} /> : <Desktop {...props} />;
};

export default Menu;
